/**
 * Maps a log severity level to a log severity number.
 *
 * @see LogSeverityLevel
 */
const SEVERITY_TEXT_TO_SEVERITY_NUMBER = {
  trace: 1,
  debug: 5,
  info: 9,
  warn: 13,
  error: 17,
  fatal: 21
};
export { SEVERITY_TEXT_TO_SEVERITY_NUMBER };
